import axios from 'axios';

// Exportación con nombre para authGetFetcher
export function authGetFetcher(token) {
  return async (url) => {
    const { data } = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    return data;
  };
}

// Exportación con nombre para authPostFetcherProveedores
export function authPostFetcherProveedores(token, nombre, rol) {
  return async (url) => {
    const { data } = await axios.post(
      url,
      { nombre, rol }, // Envías el nombre y el rol en el cuerpo de la solicitud
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );
    return data;
  };
}



// Exportación por defecto
const authFetcher = { authGetFetcher, authPostFetcherProveedores };
export default authFetcher;
