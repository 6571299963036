import { useEffect, useState } from 'react';
import { useUsuarioContexto } from '../../proveedor/usuario';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { authGetFetcher, authPostFetcherProveedores } from '../../utils/db/fetcher'; 

import { urlEcommerceBackend } from '../../recursos/configuracion';

export default function Proveedores() {
  const cuenta = useUsuarioContexto();
  const [permisos, setpermisos] = useState([]);
  const navigate = useNavigate();
  // const fetcher = authGetFetcher(cuenta.token);
  const fetcher = authPostFetcherProveedores(cuenta.token, cuenta.nombre, cuenta.rol);
  const { data, isLoading } = useSWR(`${urlEcommerceBackend}/proveedores`, fetcher);
  console.log(data)
  useEffect(() => {
    if (cuenta.interfaces.length > 0) {
      const newPermisos = cuenta.interfaces.find((interfaz) => interfaz.nombre === 'proveedor');
      setpermisos(newPermisos?.permiso || []);
    }
  }, [cuenta]);
                                                                                                                                                                                                                                   
  return (
    <div className='container-fluid mt-3 mb-3'>
      <h1 className='mt-2'>Proveedores</h1>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Proveedor</th>
              <th>Productos</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ||
            data?.arrayJson.filter((prov) =>
              // permisos.map((per) => per.toLowerCase()).includes(prov.nombre.toLowerCase())
              true
            ).length > 0 ? (
              data?.arrayJson
                // .filter((prov) => permisos.map((per) => per.toLowerCase()).includes(prov.nombre.toLowerCase()))
                .map((proveedor) => (
                  <tr key={proveedor.codigo}>
                    <td>{proveedor.codigo}</td>
                    <td>{proveedor.nombre}</td>
                    <td>{proveedor.count_productos}</td>
                    <td>
                      <Button onClick={() => navigate(`/proveedores/${proveedor.codigo}/1/10`)}>Acceder</Button>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={3} className='text-center'>
                  No hay Proveedores disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
