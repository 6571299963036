import { useEffect, useState } from 'react';
import { Button, Card, Form, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { urlEcommerceBackend } from '../../recursos/configuracion';
import { useParams, useNavigate } from 'react-router-dom';
import { useUsuarioContexto } from '../../proveedor/usuario';
import Swal from 'sweetalert2';


export default function AgregarProducto() {
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');
  const { proveedorID } = useParams(); // Obtenemos proveedorID desde la ruta
  const cuenta = useUsuarioContexto();



  const [familias, setFamilias] = useState([]);
  const [subFamilias, setSubFamilias] = useState([]);
  const [marcas, setMarcas] = useState([]);


  const [familiaSeleccionada, setFamiliaSeleccionada] = useState('');
  const [subFamiliaSeleccionada, setSubFamiliaSeleccionada] = useState('');
  const [marcaSeleccionada, setMarcaSeleccionada] = useState('');


  const navegar = useNavigate();

  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      nombre: '',
      costo: '',
      ruta: '',
      marca: '',
      // codigo: '',
      stock: '', // Añadimos campo Stock
      modelo: '' // Añadimos campo Modelo
    }
  });

  useEffect(() => {

    async function fetchCategorias() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/categorias`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          setCategorias(response.data.arrayJson);
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchCategorias();

    async function fetchFamilias() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/familias`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          console.log('Familias', response.data);
          setFamilias(response.data.arrayJson);
        } else {
          console.log('No se econtraron familias');
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchFamilias();


    async function fetchSubFamilias() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/subFamilias`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          console.log('SubFamilias', response.data);
          setSubFamilias(response.data.arrayJson);
        } else {
          console.log('No econtraron subfamilias');
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchSubFamilias();

    async function fetchMarcas() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/marcasEcont`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          console.log('Marcas Econt', response.data);
          setMarcas(response.data.arrayJson);
        } else {
          console.log('No encontraron marcas');
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchMarcas();

  }, [cuenta.token]);

  useEffect(() => {
    reset((prevValues) => ({
      ...prevValues,
      ruta: categoriaSeleccionada,
    }));
  }, [categoriaSeleccionada, reset]);

  useEffect(() => {
    // Establecer un valor por defecto al cargar el componente
    setValue('codigoBarra', '');
  }, [setValue]);

  const onSubmit = async (data) => {
    const parametros = {
      // codigo: data.codigo,
      codigoBarra: data.codigoBarra,
      nombre: data.nombre,
      descripcion: data.descripcion,
      costo: data.costo,
      categoria: categoriaSeleccionada,
      // marca: data.marca,
      cantidad: data.stock, // Enviamos Stock
      modelo: data.modelo, // Enviamos Modelo
      familia: familiaSeleccionada,
      subFamilia: subFamiliaSeleccionada,
      marca: marcaSeleccionada,
    };

    try {
      console.log('parametros', parametros);

      let respuesta = await Axios.post(`${urlEcommerceBackend}/proveedores/registro/${proveedorID}`, parametros, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });

      console.log('Respuesta del servidor:', respuesta.data.arrayJson[0].codigo);

      navegar(`/proveedores/${proveedorID}/${respuesta.data.arrayJson[0].codigo}/editar`);

    } catch (error) {
      Swal.fire({
        title: 'Complete todos los campos por favor',
        icon: 'error'
      });
      console.error('Error al guardar el producto', error);
    }
  };

  return (
    <div className='d-flex justify-content-center align-items-center mt-4'>
      <Card className='p-4' style={{ width: '30rem' }}>
        <h1 className='text-center'>Agregar Producto</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Campo Código */}
          {/* Se elimina porque el econt brindará un último número a la hora de crear
          el artículo */}
          {/* <Form.Group className='mb-3'>
            <Form.Label>Código</Form.Label>
            <Form.Control
              type='text'
              {...register('codigo', { required: 'El código es obligatorio' })}
              isInvalid={!!errors.codigo}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.codigo?.message}
            </Form.Control.Feedback>
          </Form.Group> */}

          {/* Campo Nombre */}
          <Form.Group className='mb-3'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type='text'
              {...register('nombre', { required: 'El nombre es obligatorio' })}
              isInvalid={!!errors.nombre}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.nombre?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Campo Marca */}
          {/* <Form.Group className='mb-3'>
            <Form.Label>Marca</Form.Label>
            <Form.Control
              type='text'
              {...register('marca', { required: 'La marca es obligatoria' })}
              isInvalid={!!errors.marca}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.marca?.message}
            </Form.Control.Feedback>
          </Form.Group> */}

          {/* Campo Descripción */}
          <Form.Group className='mb-3'>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              {...register('descripcion')}
            />
          </Form.Group>


          {/* Campo Costo */}
          <Form.Group className='mb-3'>
            <Form.Label>Precio de costo</Form.Label>
            <Form.Control
              type='number'
              step='0.01'
              {...register('costo', {
                required: 'El costo es obligatorio',
                min: { value: 0.01, message: 'El costo debe ser mayor que 0' }
              })}
              isInvalid={!!errors.costo}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.costo?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Campo Stock */}
          <Form.Group className='mb-3'>
            <Form.Label>Stock</Form.Label>
            <Form.Control
              type='number'
              {...register('stock', {
                required: 'El stock es obligatorio',
                min: { value: 0, message: 'El stock debe ser mayor o igual a 0' }
              })}
              isInvalid={!!errors.stock}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.stock?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Campo Modelo */}
          <Form.Group className='mb-3'>
            <Form.Label>Modelo</Form.Label>
            <Form.Control
              type='text'
              {...register('modelo', { required: 'El modelo es obligatorio' })}
              isInvalid={!!errors.modelo}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.modelo?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Campo Categoría */}
          <FloatingLabel controlId='selectCategoria' label='Categoría' className='mb-3'>
            <Form.Select
              aria-label='Categoría'
              value={categoriaSeleccionada}
              onChange={(e) => setCategoriaSeleccionada(e.target.value)}
            >
              <option value=''>Seleccionar categoría</option>
              {categorias.map((categoria) => (
                <option key={categoria.ruta} value={categoria.ruta}>
                  {categoria.nombre}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>


          {/* Select Familia */}
          <FloatingLabel controlId='selectFamilia' label='Familia' className='mb-3'>
            <Form.Select
              aria-label='Familia'
              value={familiaSeleccionada}
              onChange={(e) => setFamiliaSeleccionada(e.target.value)}
            >
              <option value=''>Seleccionar familia</option>
              {familias.map((familia) => (
                <option key={familia.codigo} value={familia.codigo}>
                  {familia.nombre}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>

          {/* Select SubFamilia */}
          <FloatingLabel controlId='selectSubFamilia' label='SubFamilia' className='mb-3'>
            <Form.Select
              aria-label='SubFamilia'
              value={subFamiliaSeleccionada}
              onChange={(e) => setSubFamiliaSeleccionada(e.target.value)}
            >
              <option value=''>Seleccionar subfamilia</option>
              {subFamilias.map((subFamilia) => (
                <option key={subFamilia.codigo} value={subFamilia.codigo}>
                  {subFamilia.nombre}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>

          {/* Select Marca */}
          <FloatingLabel controlId='selectMarca' label='Marca' className='mb-3'>
            <Form.Select
              aria-label='Marca'
              value={marcaSeleccionada}
              onChange={(e) => setMarcaSeleccionada(e.target.value)}
            >
              <option value=''>Seleccionar marca</option>
              {marcas.map((marca) => (
                <option key={marca.codigo} value={marca.codigo}>
                  {marca.nombre}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>

          <Form.Group className='mb-3'>
            <Form.Label>Código de barras</Form.Label>
            <Form.Control
              type='text'
              {...register('codigoBarra', { required: 'El código de barras es obligatorio' })} // Validación requerida
              isInvalid={!!errors.codigoBarra} // Muestra el estado de error
            />
            <Form.Control.Feedback type='invalid'>
              {errors.codigoBarra?.message} // Muestra el mensaje de error
            </Form.Control.Feedback>
          </Form.Group>

          {/* Botones separados */}
          <div className='d-flex justify-content-between'>
          <Button variant='success' onClick={() => navegar(`/proveedores/${proveedorID}/1/10`)}>
              Volver
            </Button>

            <Button type='submit'>Guardar</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
