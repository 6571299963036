import { useState, useEffect, useRef } from 'react';

import { Button, Table, Pagination, Form } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Axios from 'axios';

import { authGetFetcher, authPostFetcherProveedores } from '../../utils/db/fetcher';
import { useUsuarioContexto } from '../../proveedor/usuario';
import { urlEcommerceBackend } from '../../recursos/configuracion';
import useSWR, { mutate } from 'swr'; // Asegúrate de importar 'mutate'

import ImportarExcelProveedores from '../../componentes/proveedores/ImportarExcelProveedores';


export default function Proveedor() {

  const { proveedorID, pagina, intervalo } = useParams();
  const cuenta = useUsuarioContexto();

  console.log('cuenta', cuenta);
  

  const fetcher = authGetFetcher(cuenta.token);
  const navegar = useNavigate();
  const [productos, setProductos] = useState([]); // Estado para almacenar productos

  const [currentPage, setCurrentPage] = useState(parseInt(pagina));
  const [searchTerm, setSearchTerm] = useState('');

  const manejarPaginacion = (numero) => {
    setCurrentPage(numero);
    navegar(`/proveedores/${proveedorID}/${numero}/10`);
  }

  const { data, isLoading } = useSWR(
    `${urlEcommerceBackend}/proveedores/consulta/${proveedorID}?pagina=${pagina}&intervalo=${intervalo}`,
    fetcher
  );

  useEffect(() => {
    // Si se obtienen datos desde SWR, establece los productos en el estado
    if (data && data.arrayJson) {
      setProductos(data.arrayJson);
    }
  }, [data]);

  const totalPaginas = data?.objetoJson.paginado.fin || 1;

  const maximoNumeros = 5; // Máximo de números de paginación a mostrar
  const mitadNumeros = Math.floor(maximoNumeros / 2);

  const inicioPagina = Math.max(currentPage - mitadNumeros, 1);
  const finPagina = Math.min(currentPage + mitadNumeros, totalPaginas);

  let items = [];

  if (inicioPagina > 1) {
    items.push(
      <Pagination.First key="first" onClick={() => manejarPaginacion(1)} />
    );
    items.push(
      <Pagination.Prev key="prev" onClick={() => manejarPaginacion(currentPage - 1)} disabled={currentPage === 1} />
    );
  }

  for (let number = inicioPagina; number <= finPagina; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => manejarPaginacion(number)}>
        {number}
      </Pagination.Item>
    );
  }

  if (finPagina < totalPaginas) {
    items.push(
      <Pagination.Next key="next" onClick={() => manejarPaginacion(currentPage + 1)} disabled={currentPage === totalPaginas} />
    );
    items.push(
      <Pagination.Last key="last" onClick={() => manejarPaginacion(totalPaginas)} />
    );
  }

  const handleDelete = (producto) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar ${producto.nombre}?`,
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let respuesta = await Axios.delete(`${urlEcommerceBackend}/proveedores/${proveedorID}/productos/${producto.codigo}`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        mutate(`${urlEcommerceBackend}/proveedores/consulta/${proveedorID}?pagina=${pagina}&intervalo=${intervalo}`);

        Swal.fire({
          title: 'Eliminado correctamente',
          icon: 'success'
        });
      }
    });
  };

  const handleImportComplete = () => {
    mutate(`${urlEcommerceBackend}/proveedores/consulta/${proveedorID}?pagina=${pagina}&intervalo=${intervalo}`);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
  
    if (!searchTerm.trim()) {
      // Si no hay término de búsqueda, carga todos los productos normalmente
      const response = await fetcher(`${urlEcommerceBackend}/proveedores/consulta/${proveedorID}?pagina=${pagina}&intervalo=${intervalo}`);
      console.log('response.arrayJson', response.arrayJson);
      
      setProductos(response.arrayJson);
      return;
    }
  
    try {
      const response = await Axios.get(`${urlEcommerceBackend}/proveedores/buscar/${proveedorID}`, {
        params: {
          search: searchTerm, // Envía "search" que puede ser código o nombre
        },
        headers: {
          authorization: `Bearer ${cuenta.token}`,
        },
      });
  
      if (response.data.exito) {
        console.log('response.data.data', response.data);
        
        setProductos(response.data.data);
      } else {
        console.log('No se encontraron productos');
      }
    } catch (error) {
      console.log('Error al buscar productos:', error);
    }
  };

  return (
    <div className='container-fluid mt-3 mb-3'>
      <header className='d-flex justify-content-between mb-3'>
        <h1>Listado de productos</h1>
        <div>

          <ImportarExcelProveedores proveedorID={proveedorID} onImportComplete={handleImportComplete} />

          <Link className='btn btn-primary ' to={`/proveedores/${proveedorID}/agregar`}>
            Agregar
          </Link>

        </div>
      </header>

      {/* Campo de búsqueda y paginación */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Form onSubmit={handleSearch} className="d-flex">
          <Form.Control
            type="text"
            placeholder="Buscar productos"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="me-2"
          />
          <Button variant="primary" type="submit">Buscar</Button>
        </Form>

        {/* Paginación centrada */}
        <Pagination>{items}</Pagination>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Imagen</th>
            <th>Nombre</th>
            <th>Marca</th>
            <th>Costo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            productos.length > 0
              ? productos.map((producto) => (
                <tr key={producto.codigo}>
                  <td>{producto.codigo}</td>
                  <td>
                    {producto.imagenes && producto.imagenes.length > 0 && producto.imagenes[0].url['100'] ? (
                      <img src={`https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/store/${producto.imagenes[0].url['100']}`} alt='producto' width={100} />
                    ) : (
                      <span>Sin imagen</span>
                    )}
                  </td>
                  <td>{producto.nombre}</td>
                  <td>{typeof producto.marca === 'string' ? producto.marca : producto.marca.nombre}</td>
                  <td>{parseInt(producto.costo).toLocaleString('es-PY')} Gs</td>
                  <td className='d-flex flex-column gap-1'>
                    <Link to={`/proveedores/${proveedorID}/${producto.codigo}/editar`} className='btn btn-primary'>
                      Editar
                    </Link>
                    <Button variant='danger' onClick={() => handleDelete(producto)}>
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))
              : (
                <tr>
                  <td colSpan={7} className='text-center'>
                    No se encontraron productos.
                  </td>
                </tr>
              )
          ) : (
            <tr>
              <td colSpan={7} className='text-center'>
                Cargando...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
