import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { BarraSuperior } from './componentes/BarraSuperior';
//import { BarraInferior } from './componentes/BarraInferior';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './estilos/General.css';
import { UsuarioProveedor } from './proveedor/usuario';
import { ContadoresProveedor } from './proveedor/contadores';
import { EditorProveedor } from './proveedor/editor';
import { ProcesosProveedor } from './proveedor/procesos';
import Acceso from './paginas/acceso';
import Principal from './paginas/principal';
import Etiquetas from './paginas/etiquetas';
import Clasificacion from './paginas/clasificacion';
import Caracteristica from './paginas/caracteristica';
import Productos from './paginas/productos';
import Producto from './paginas/producto';
import Promociones from './paginas/promociones';
import Promocion from './paginas/promocion';
import Publicidad from './paginas/publicidad';
import Portada from './paginas/portada';
import Banner from './paginas/banner';
import Contador from './paginas/contador';
import Sello from './paginas/sello';
import Galeria from './paginas/galeria';
import Panel from './paginas/panel';
import Carritos from './paginas/carritos';
import Carrito from './paginas/carrito';
import Solicitudes from './paginas/solicitudes';
import Solicitud from './paginas/solicitud';
import Coleccion from './paginas/coleccion';
import Caracteristicas from './paginas/caracteristicas';
import Categorias from './paginas/categorias';
import Editor from './paginas/editor';
import Correo from './paginas/correo';
import Cuentas from './paginas/cuentas';
import Marcas from './paginas/marcas';
import Promocionado from './paginas/promocionado';
import Masvendidos from './paginas/masvendidos';
import Emergentes from './paginas/emergentes';
import Emergente from './paginas/emergente';
import Relacion from './paginas/relacion';
import Cuenta from './paginas/cuenta';
import Video from './paginas/videos';
import Videos from './paginas/videos';
import Combos from './paginas/combos';
import VerCombo from './paginas/combo';
import ConfiguracionCombo from './paginas/configuracionCombo';
import Proveedores from './paginas/proveedores/proveedores';
import Proveedor from './paginas/proveedores/proveedor';
import AgregarProducto from './paginas/proveedores/agregar';
import EditarProducto from './paginas/proveedores/editar';

function App() {
  return (
    <BrowserRouter>
      <UsuarioProveedor>
        <ContadoresProveedor>
          <ProcesosProveedor>
            <EditorProveedor>
              <BarraSuperior />
              <Routes>
                <Route path='/' index element={<Navigate to='/acceso' />} />
                <Route path='/acceso' index element={<Acceso />} />
                <Route path='/principal' index element={<Principal />} />
                <Route path='/panel' element={<Panel />} />
                <Route path='/editor' element={<Navigate to='/editor/0' />} />
                <Route path='/editor/:codigo' element={<Editor />} />
                <Route path='/cuentas' element={<Navigate to='/cuentas/1/10' />} />
                <Route path='/cuentas/:pagina/:intervalo' element={<Cuentas />} />
                <Route path='/cuenta/:codigo' element={<Cuenta />} />
                <Route path='/etiquetas' element={<Etiquetas />}>
                  <Route path='caracteristica/:pagina/:intervalo' element={<Caracteristica />} />
                  <Route path='clasificacion/:pagina/:intervalo' element={<Clasificacion />} />
                  <Route path='relacion/:pagina/:intervalo' element={<Relacion />} />
                </Route>
                <Route path='/productos' element={<Navigate to='/productos/1/10/tm/tc' />} />
                <Route path='/productos/:pagina/:intervalo/:marca/:categoria' element={<Productos />} />
                <Route path='/producto' element={<Navigate to='/productos/1/10/tm/tc' />} />
                <Route path='/producto/:codigo' element={<Producto />} />
                <Route path='/publicidad' element={<Publicidad />}>
                  <Route path='promociones/:pagina/:intervalo' element={<Promociones />} />
                  <Route path='combos/:pagina/:intervalo' element={<Combos />} />
                  <Route path='emergentes/:pagina/:intervalo' element={<Emergentes />} />
                  <Route path='portada' index element={<Portada />} />
                  <Route path='banner' element={<Banner />} />
                  <Route path='contador' element={<Contador />} />
                  <Route path='sello' element={<Sello />} />
                  <Route path='videos' element={<Videos />} />
                </Route>
                <Route path='combos/:id' element={<VerCombo />} />
                <Route path='configuracionCombo/:id' element={<ConfiguracionCombo />} />
                <Route path='/emergente/:ruta' element={<Emergente />} />
                <Route path='/promocion' element={<Navigate to='/publicidad' />} />
                <Route path='/promocion/:ruta' element={<Promocion />} />
                <Route path='/promocionado/:ruta/:pagina/:intervalo' element={<Promocionado />} />
                <Route path='/galeria/:codigo' element={<Galeria />} />
                <Route path='/carritos' element={<Navigate to='/carritos/1/10' />} />
                <Route path='/carritos/:pagina/:intervalo' element={<Carritos />} />
                <Route path='/carrito/:codigo' element={<Carrito />} />
                <Route path='/solicitudes' element={<Navigate to='/solicitudes/1/10' />} />
                <Route path='/solicitudes/:pagina/:intervalo' element={<Solicitudes />} />
                <Route path='/solicitud/:codigo' element={<Solicitud />} />
                <Route path='/correo' element={<Navigate to='/correo/1/10' />} />
                <Route path='/correo/:pagina/:intervalo' element={<Correo />} />
                <Route path='/masvendidos' element={<Masvendidos />} />
                <Route path='/coleccion' element={<Coleccion />}>
                  <Route path='caracteristicas' index element={<Caracteristicas />} />
                  <Route path='categorias' index element={<Categorias />} />
                  <Route path='marcas' index element={<Marcas />} />
                </Route>
                <Route path='/proveedores' element={<Proveedores />} />
                <Route path='/proveedores/:proveedorID/:pagina/:intervalo' element={<Proveedor />} />
                <Route path='/proveedores/:proveedorID/agregar' element={<AgregarProducto />} />
                <Route path='/proveedores/:proveedorID/:producto/editar' element={<EditarProducto />} />
              </Routes>
            </EditorProveedor>
          </ProcesosProveedor>
        </ContadoresProveedor>
      </UsuarioProveedor>
    </BrowserRouter>
  );
}

export default App;
